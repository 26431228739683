import * as React from "react"
import "@fortawesome/fontawesome-free/css/all.css"
import Header from "./Header"

import "../styles/index.scss"

const Application = () => {
  return (
    <div>
      <Header>
        <div>application</div>
      </Header>
    </div>
  )
}

export default Application
